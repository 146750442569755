<template>
  <div>
    <c-tab
      :dense="true"
      type="pagetop"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      align="left"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'safe-work-permit-state',
  data() {
    return {
      searchUrl: '',
      tab: 'state001',
      tabItems: [
        { name: 'state001', icon: 'list', label: '년도별 통계', component: () => import(`${'./safeWorkPermitStateByYear.vue'}`) },
        { name: 'state002', icon: 'list', label: '공정별 통계', component: () => import(`${'./safeWorkPermitStateByprocess.vue'}`) },
        { name: 'state003', icon: 'list', label: '설비별 통계', component: () => import(`${'./safeWorkPermitStateByEquip.vue'}`) },
        { name: 'state004', icon: 'list', label: '작업장소별 통계', component: () => import(`${'./safeWorkPermitStateByLocation.vue'}`) },
        { name: 'state005', icon: 'list', label: '외주업체별 통계', component: () => import(`${'./safeWorkPermitStateByVendor.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>
